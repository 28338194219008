import { AddPaymentInstrument, IElementFactory } from "@cspayments/pidl-react";
import { FluentUIElementFactory } from "@cspayments/pidl-fluent-ui";
import { useState } from "react";
import './AddCreditCard.css';
import { newGuid } from "@microsoft/applicationinsights-core-js";

export interface IAddCreditCardProps {
    env: string,
    sessionId: string,
    country: string,
    partner: string,
    language: string,
    paymentType: string,
    paymentFamily: string,
    scenario: string,
  } 

  export declare type PidlEvent = (name: string, parameters: any) => void;

  function AddCreditCard(props: IAddCreditCardProps) {
    const fluentUi: IElementFactory =  new FluentUIElementFactory()
  
    const [canShowOverlay, SetCanShowOverlay] = useState(false);

    const onBusy = (isBusy: boolean) => {
        SetCanShowOverlay(isBusy);
    }

    const onClose = () => {
        console.log("Close");
        window.open("about:blank", "_self") // TODO: Fix depending on what we decide for close button
        window.close();
    }

    const greenId = newGuid(); 
    const fptUrl = `https://fpt.microsoft.com/tags?session_id=${greenId}`;

    const addPiComponentProps : any = {
        country : props.country,
        elementFactory : fluentUi,
        env : props.env,
        language: props.language,
        partner: props.partner,
        onSuccess: () => {window.location.href = `https://www.microsoft.com/${props.language}/store/rds/v2/GeneralAddPISuccess`} , //https://www.w3schools.com/howto/howto_js_redirect_webpage.asp
        onFailure:() => {window.location.href = `https://www.microsoft.com/${props.language}/store/rds/v2/GeneralAddPIFailure`} ,
        onCancel: () => {onClose()},
        sessionId: props.sessionId,
        paymentType: props.paymentType,
        paymentFamily: props.paymentFamily,
        greenId: greenId,
        showSubmitBlock: true,
        completePrerequisites: false,
        scenario: props.scenario,
        headers:{"x-ms-flight": "pxpidl"}
    }

    return (
        <div className = "App">
        <style>
          {`
            .payments-manager-fpt {
              float: left;
              position:absolute;
              top:-700px;
              left:-700px;
              border: 0;
          `}
        </style>
        <iframe className="payments-manager-fpt" src={fptUrl} aria-hidden="true"></iframe>
        <AddPaymentInstrument 
            {...addPiComponentProps}
            />
        {canShowOverlay ? <div id="overlay" /> : null}
        </div>
    );  
  }  

  export default AddCreditCard;